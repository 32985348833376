import { CaretDownOutlined } from '@ant-design/icons';
import { Dropdown, Button } from 'antd';
import { MenuItem } from '../shared/helper';

interface FilterDropDownProps {
  isFilterDisabled: boolean;
  dropdownitems: MenuItem[];
  onFilterChange: (payload: string) => void;
  title: string;
}
export const FilterDropDown = ({
  isFilterDisabled,
  dropdownitems,
  onFilterChange,
  title,
}: FilterDropDownProps) => {
  return (
    <Dropdown
      trigger={['click']}
      disabled={isFilterDisabled}
      menu={{
        items: dropdownitems,
        onClick: (e) => {
          e.domEvent.preventDefault();
          onFilterChange(e.key);
        },
      }}
    >
      <Button>
        {title} <CaretDownOutlined />
      </Button>
    </Dropdown>
  );
};
