import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../shared/store';
import { setLanguage } from './i18nSlice';

export const useTranslations = () => {
  const dispatch = useDispatch();

  const t: { [key: string]: string } = useSelector((state: RootState) => state.i18n.translations);

  const setLang = (lang: string) => dispatch(setLanguage(lang));
  const lang = useSelector((state: RootState) => state.i18n.lang);
  const supportedLangs = useSelector((state: RootState) => state.i18n.supportedLangs);
  const status = useSelector((state: RootState) => state.i18n.status);

  return {
    t,
    lang,
    setLang,
    init: setLang,
    supportedLangs,
    status,
  };
};
