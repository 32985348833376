export const UnitLabels: { [key: string]: string } = {
  Percentage: '%',
  Time_Sec: ' sec',
  Time_Min: ' min',
  Currency: '',
  Number: '',
};

export const AMC_VIEWS: { [key: string]: string } = {
  ACOOUNT_MANAGER: 'amc',
  TEAM_LEAD: 'amc_tl',
  REGIONAL_TEAM_LEAD: 'amc_rtl',
};
