import { createSlice } from '@reduxjs/toolkit';
import { dateRangeModalMoleculeTranslations } from '../../components/date-range-modal-molecule/date-range-modal-molecule.translations';
import { VendorDashboardLayoutTranslations } from '../../components/metrics-tab-view-container/metrics-tab-view-container.translation';

export interface I18nState {
  status: boolean;
  lang: string;
  supportedLangs: { [key: string]: string };
  resolvedTranslations: {
    [key: string]: {
      [key: string]: {
        [key: string]: string;
      };
    };
  };
  translations: { [key: string]: string };
}
const resolvedTranslations: {
  [key: string]: {
    [key: string]: {
      [key: string]: string;
    };
  };
} = {
  dateRangeModalMoleculeTranslations,
  VendorDashboardLayoutTranslations,
};
const initialState: I18nState = {
  status: false,
  lang: 'en',
  supportedLangs: {
    en: 'English',
    de: 'Deutsche',
  },
  resolvedTranslations,
  translations: Object.keys(resolvedTranslations).reduce((acc, translationKey) => {
    const keyTranslations = resolvedTranslations[translationKey];
    let newAcc = {};
    Object.keys(keyTranslations).forEach((keyTranslationsKey) => {
      newAcc = {
        ...newAcc,
        [keyTranslationsKey]: keyTranslations[keyTranslationsKey].en,
      };
    });

    return {
      ...acc,
      ...newAcc,
    };
  }, {}),
};

export const i18nSlice = createSlice({
  name: 'i18n',
  initialState,
  reducers: {
    setLanguage: (state, action) => {
      state.lang = action.payload;
      state.translations = Object.keys(state.resolvedTranslations).reduce((acc, translationKey) => {
        const keyTranslations = state.resolvedTranslations[translationKey];
        let newAcc = {};
        Object.keys(keyTranslations).forEach((keyTranslationsKey) => {
          newAcc = {
            ...newAcc,
            [keyTranslationsKey]: keyTranslations[keyTranslationsKey][state.lang],
          };
        });

        return {
          ...acc,
          ...newAcc,
        };
      }, {});
    },
  },
});
export const { setLanguage } = i18nSlice.actions;

export const i18nReducer = i18nSlice.reducer;
