type Page = 'PortfolioOverview' | 'VendorDashboard';
type Action = 'click' | 'hover' | 'navigation';
export type ActionData = { [key: string]: unknown };

export type SalesforceEvent = {
  operation: Page;
  status: 'success' | 'failure';
  action: Action;
  actionData?: ActionData;
};

export const triggerSalesforceEvent = (page: Page, action: Action, actionData?: ActionData) => {
  const event: SalesforceEvent = {
    status: 'success',
    action,
    operation: page,
    actionData,
  };
  /* eslint-disable no-restricted-globals */
  parent.postMessage(event, '*');
};

export const useSalesforceTrigger = (page: Page) => {
  const TIMEOUT = 30000;
  const triggerHover = (mouseState: string) => {
    let timer!: NodeJS.Timeout;
    if (mouseState === 'mouseenter') {
      timer = setTimeout(() => {
        triggerSalesforceEvent(page, 'hover');
      }, TIMEOUT);
    } else {
      clearTimeout(timer);
    }
  };
  const triggerClick = (actionData: ActionData) => {
    triggerSalesforceEvent(page, 'click', actionData);
  };
  return { triggerHover, triggerClick };
};
