import React from 'react';
import { Typography } from 'antd';
import Space from 'antd/es/space';
import Spin from 'antd/es/spin';
import { ChartKPIs, KPIConfig } from '../../models/kpi.model';
import { MetricsBarChart } from '../metrics-bar-chart/metrics-bar-chart';
import { tabs } from './charts-def';
import './charts-container.scss';

const { Text, Title } = Typography;
interface Props {
  tab: 'commercial' | 'operational';
  chartData: ChartKPIs[];
  prevChartData: ChartKPIs[];
  labels: string[];
  kpiConfig: KPIConfig[];
  loading: boolean;
  error: boolean;
}
export const ChartContainer = ({
  tab,
  chartData,
  prevChartData,
  loading,
  error,
  labels,
  kpiConfig,
}: Props) => {
  if (loading) {
    return (
      <div style={{ height: '230px' }} className="loading">
        <Spin tip="Loading..." />
      </div>
    );
  }
  if (error) {
    return (
      <div className="warning-message-container">
        <Title level={5}>Something went wrong. Please try again later.</Title>
      </div>
    );
  }
  if (!chartData?.length || !kpiConfig?.length) {
    return (
      <Space direction="vertical" align="start">
        <Text>
          No data is available for that period of time to populate charts. Please choose other
          dates..
        </Text>
        <img alt="" src="/illustration.png" />
      </Space>
    );
  }

  return (
    <div className="chart-container">
      {tabs[tab]
        .filter((t) => kpiConfig.some((kpi) => kpi.kpiName === t))
        .map((kpi_key) => (
          <MetricsBarChart
            key={kpi_key}
            labels={labels}
            dataKey={kpi_key}
            chartData={chartData}
            prevChartData={prevChartData}
            kpiConfig={kpiConfig}
          />
        ))}
    </div>
  );
};
