/* eslint-disable max-lines */
import React, { useCallback, useState } from 'react';
import { Spin, Typography } from 'antd';
import { useSearchParams } from 'react-router-dom';
import { AMC_VIEWS } from '../../models/constants';
import { MomentDate } from '../../models/date.model';
import { getIsInvalid, getIsLoaded } from '../../services/auth/auth-slice';
import { Filter, getquarterFilterOptions } from '../../shared/helper';
import { useAppSelector } from '../../shared/hooks';
import {
  ActionData,
  triggerSalesforceEvent,
  useSalesforceTrigger,
} from '../../shared/useAnalytics';
import { useAccountManagerHierarchy } from '../portfolio-overview/useAccountManagerHierarchy';
import { useFilterData } from '../portfolio-overview/useFilterData';
import { ChartContainer } from './charts-containter';
import { PortfolioOverviewHeader } from './portfolio-overview-header';
import { PortfolioOverviewTable } from './portfolio-overview-table';
import { useChartKpis } from './useChartKpis';
import { usePortfolioOverviewTable } from './usePortfolioOverviewTable';
import '../portfolio-overview/portfolio-overview.scss';

const { Title } = Typography;

export const PortfolioOverviewHybrid: React.FC = () => {
  const [searchParams] = useSearchParams();
  const sfToken: string = searchParams.get('sessionId') || '';
  const view = searchParams.get('view') || AMC_VIEWS.ACOOUNT_MANAGER;
  const { triggerHover } = useSalesforceTrigger('PortfolioOverview');
  const isInvalidSession = useAppSelector(getIsInvalid);
  const dateFilterOptions: Filter = getquarterFilterOptions();
  const [state, setState] = useState({
    accountOwnerIds: [] as string[],
    searchText: '',
    dateRange: {
      to: dateFilterOptions.QTD.end as MomentDate,
      from: dateFilterOptions.QTD.start as MomentDate,
      precision: 'quarter' as 'month' | 'quarter' | 'day',
    },
    filters: {
      verticals: [] as string[],
      brands: [] as string[],
      groups: [] as string[],
      cities: [] as string[],
    },
    tab: 'commercial' as 'commercial' | 'operational' | 'all',
  });
  const { amHierarchyLoading } = useAccountManagerHierarchy();
  const { table, isLoading, isRefetching, error } = usePortfolioOverviewTable(
    view,
    sfToken,
    state,
    setState,
  );
  const { chartData, chartDataLoading, chartDataError, prevChartData } = useChartKpis(state);
  const { filterData } = useFilterData(state.accountOwnerIds);

  const onTargetedAMsChange = useCallback((targetedAccountOwnerIds: string[]) => {
    setState((prevState) => ({ ...prevState, accountOwnerIds: targetedAccountOwnerIds }));
  }, []);

  const isSessionLoaded = useAppSelector(getIsLoaded);

  const onRangeChange = useCallback(
    (from: MomentDate, to: MomentDate, precision: 'month' | 'quarter' | 'day') => {
      const dateRangeSelection = { to, from, precision };
      const actionData: ActionData = {
        duration: { value: to?.diff(from, 'days').toString() || '', unit: 'days' },
        actionName: 'filter',
      };
      triggerSalesforceEvent('PortfolioOverview', 'click', actionData);
      setState((prevState) => ({ ...prevState, dateRange: dateRangeSelection }));
    },
    [],
  );

  const onFiltersChange = useCallback(
    (filter: { verticals: string[]; brands: string[]; groups: string[]; cities: string[] }) => {
      setState((prevState) => ({ ...prevState, filters: filter }));
    },
    [],
  );

  const onTabChange = (tabValue: 'commercial' | 'operational' | 'all') => {
    setState((prevState) => ({ ...prevState, tab: tabValue }));
  };

  const onSearchTextChange = useCallback((text: string) => {
    setState((prevState) => ({ ...prevState, searchText: text }));
  }, []);

  if (isInvalidSession) {
    return (
      <div className="warning-message-container">
        <Title level={5}>Invalid token</Title>
      </div>
    );
  }

  if (!isSessionLoaded || amHierarchyLoading) {
    return (
      <div className="loading-container">
        <Spin tip="Loading..." />
      </div>
    );
  }

  return (
    <div
      onMouseEnter={(e) => triggerHover(e.type)}
      onMouseLeave={(e) => triggerHover(e.type)}
      className="portfolio-overview-organism"
    >
      <PortfolioOverviewHeader
        onTargetedAMsChange={onTargetedAMsChange}
        onFiltersChange={onFiltersChange}
        isFilterDisabled={isLoading || isRefetching}
        onRangeChange={onRangeChange}
        onTabChange={onTabChange}
        onSearch={onSearchTextChange}
        view={view}
        filterData={filterData}
      >
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <ChartContainer
            chartData={chartData}
            prevChartData={prevChartData}
            loading={chartDataLoading}
            error={chartDataError}
          />
          <PortfolioOverviewTable
            table={table}
            error={!!error}
            loading={isLoading || isRefetching}
          />
        </div>
      </PortfolioOverviewHeader>
    </div>
  );
};
