import { Space, Typography, Flex } from 'antd';
import { DataGridData } from '../../models/kpi.model';
import { KpiCard } from '../kpi-card/kpi-card';
import { columns } from '../tab-view/card-def';
import './section-view.scss';

interface SectionViewProps {
  dailyData: DataGridData;
  monthlyData: DataGridData;
  dailyDataError: string | boolean | undefined;
  monthlyDataError: string | boolean | undefined;
}
const { Text } = Typography;

export const SectionView = ({
  dailyData,
  monthlyData,
  dailyDataError,
  monthlyDataError,
}: SectionViewProps) => {
  return (
    <Flex className="section-view" gap="small" vertical>
      <Flex gap="small" vertical key={1}>
        <Text>Revenue</Text>
        {dailyDataError || !dailyData?.kpis?.length ? (
          <Space direction="vertical" align="start">
            <Text>
              {dailyDataError ||
                'No data is available for that period of time. Please choose other dates.'}
            </Text>
            <img alt="" src="/illustration.png" />
          </Space>
        ) : (
          <Space align="start" size={[8, 8]} wrap>
            {columns.revenue.map((kpidefinition) => {
              const kpi = dailyData?.kpis?.find((obj) => obj.name === kpidefinition.id);
              return (
                kpi && (
                  <KpiCard
                    prevInterval={dailyData.prevInterval}
                    kpi={kpi}
                    key={`kpi-key-${kpi.name}`}
                  />
                )
              );
            })}
          </Space>
        )}
      </Flex>
      <Flex gap="small" vertical key={2}>
        <Text>Commercial</Text>
        {monthlyDataError || !monthlyData?.kpis?.length ? (
          <Space direction="vertical" align="start">
            <Text>
              {monthlyDataError ||
                'No data is available for that period of time. Please choose other dates.'}
            </Text>

            <img alt="" src="/illustration.png" />
          </Space>
        ) : (
          <Space align="start" size={[8, 8]} wrap>
            {columns.commercial.map((kpidefinition) => {
              const kpi = monthlyData?.kpis?.find((obj) => obj.name === kpidefinition.id);
              return (
                kpi && (
                  <KpiCard
                    kpi={kpi}
                    key={`kpi-key-${kpi.name}`}
                    prevInterval={monthlyData.prevInterval}
                    subKpis={kpidefinition?.children?.map((def) =>
                      monthlyData?.kpis?.find((subKpi) => subKpi.name === def.id),
                    )}
                  />
                )
              );
            })}
          </Space>
        )}
      </Flex>
      <Flex gap="small" vertical key={3}>
        <Text>Operations</Text>
        {dailyDataError || !dailyData?.kpis?.length ? (
          <Space direction="vertical" align="start">
            <Text>
              {dailyDataError ||
                'No data is available for that period of time. Please choose other dates.'}
            </Text>
            <img alt="" src="/illustration.png" />
          </Space>
        ) : (
          <Space align="start" size={[8, 8]} wrap>
            {columns.operations.map((kpidefinition) => {
              const kpi = dailyData?.kpis?.find((obj) => obj.name === kpidefinition.id);
              return (
                kpi && (
                  <KpiCard
                    prevInterval={dailyData.prevInterval}
                    kpi={kpi}
                    key={`kpi-key-${kpi.name}`}
                  />
                )
              );
            })}
          </Space>
        )}
      </Flex>
    </Flex>
  );
};
