import React, { useState } from 'react';
import {
  CaretDownOutlined,
  CaretUpOutlined,
  DownOutlined,
  InfoCircleOutlined,
  UpOutlined,
} from '@ant-design/icons';
import { Space, Tooltip, Typography } from 'antd';
import { KPI } from '../../../models/kpi.model';

import '../kpi-card.scss';

const { Text } = Typography;

interface Props {
  kpi: KPI;
  subKpis?: (KPI | undefined)[];
  prevInterval: string;
}

function formatValue(value: string): string {
  if (!Number.isNaN(value)) {
    return Number.isInteger(Number(value)) && Number(value) !== 0
      ? Number(value).toString()
      : Number(value).toFixed(1);
  }
  return '';
}

export const KpiCard = ({
  kpi: {
    name,
    nameInDashboard,
    value,
    percentageChange,
    previousValue,
    tooltipText,
    downColor,
    upColor,
    unit,
    type,
  },
  subKpis,
  prevInterval,
}: Props) => {
  const [isAccordionActive, setIsAccordionActive] = useState(false);
  const onKpiCardClick: React.MouseEventHandler<HTMLDivElement> = () => {
    setIsAccordionActive(!isAccordionActive);
  };
  return (
    <Space onClick={onKpiCardClick} size={0} direction="vertical" className="kpi-card">
      <Space className="sub-kpis">
        <Text className="card-text">{nameInDashboard || name}</Text>
        <Space>
          <Tooltip title={tooltipText}>
            <InfoCircleOutlined />
          </Tooltip>
          {subKpis && (isAccordionActive ? <UpOutlined /> : <DownOutlined />)}
        </Space>
      </Space>
      <Space>
        <Text className="kpi-value">
          {`${formatValue(value)}`} {`${type === 'percent' ? '%' : unit || ''}`}
        </Text>
        {percentageChange && (
          <span
            style={{
              color: parseInt(percentageChange!, 10) > 0 ? upColor : downColor,
              display: 'flex',
            }}
          >
            {parseInt(percentageChange, 10) > 0 ? (
              <CaretUpOutlined className="arrow" />
            ) : (
              <CaretDownOutlined className="arrow" />
            )}
            <Text
              style={{
                color: parseInt(percentageChange!, 10) > 0 ? upColor : downColor,
              }}
            >
              {`${Math.abs(parseInt(percentageChange!, 10))}%`}
            </Text>
          </span>
        )}
      </Space>
      <Space>
        <Text className="card-text">
          {formatValue(previousValue)} {`${type === 'percent' ? '%' : unit || ''}`}
        </Text>
        <Text className="prev-interval">{prevInterval}</Text>
      </Space>
      {subKpis && isAccordionActive && <br />}
      {isAccordionActive &&
        subKpis?.map(
          (kpi) =>
            kpi && (
              <Space className="sub-kpis" key={`sub-kpi-key-${kpi.id}`}>
                <Text className="card-text">{kpi.nameInDashboard || kpi.name}</Text>
                <Space>
                  {' '}
                  <Text className="card-text">
                    {' '}
                    {`${formatValue(kpi.value)}`}{' '}
                    {`${kpi.type === 'percent' ? '%' : kpi.unit || ''}`}
                  </Text>
                  {kpi.percentageChange && (
                    <span
                      style={{
                        color: parseInt(kpi.percentageChange, 10) > 0 ? kpi.upColor : kpi.downColor,
                        display: 'flex',
                      }}
                    >
                      {parseInt(kpi.percentageChange, 10) > 0 ? (
                        <CaretUpOutlined className="arrow" />
                      ) : (
                        <CaretDownOutlined className="arrow" />
                      )}
                      <Text
                        className="card-text"
                        style={{
                          color:
                            parseInt(kpi.percentageChange, 10) > 0 ? kpi.upColor : kpi.downColor,
                        }}
                      >
                        {' '}
                        {`${Math.abs(parseInt(kpi.percentageChange, 10))}%`}
                      </Text>
                    </span>
                  )}
                </Space>
              </Space>
            ),
        )}
    </Space>
  );
};
