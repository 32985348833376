import React from 'react';
import { flexRender, Row, Table } from '@tanstack/react-table';
import { Spin, Typography } from 'antd';
import { useVirtual } from 'react-virtual';
import { PortfolioOverviewKPIs } from '../../models/kpi.model';
import './portfolio-performance-table.scss';

interface PortfolioPerformanceTableProps {
  table: Table<PortfolioOverviewKPIs>;
  tableContainerRef: React.RefObject<HTMLDivElement>;
  fetchMoreOnBottomReached: (containerRefElement?: HTMLDivElement | null) => void;
  isFetchingNextPage: boolean;
  totalDBRowCount: number;
  error: boolean;
  loading: boolean;
}
export const PortfolioPerformanceTable = ({
  error,
  loading,
  table,
  tableContainerRef,
  fetchMoreOnBottomReached,
  isFetchingNextPage,
  totalDBRowCount,
}: PortfolioPerformanceTableProps) => {
  const { Title } = Typography;
  const { rows } = table.getRowModel();
  // Virtualizing is optional, but might be necessary if we are going to potentially have hundreds or thousands of rows
  const rowVirtualizer = useVirtual({
    parentRef: tableContainerRef,
    size: rows.length,
    overscan: 10,
    estimateSize: React.useCallback(() => 36, []),
  });
  const { virtualItems: virtualRows, totalSize } = rowVirtualizer;
  const paddingTop = virtualRows.length > 0 ? virtualRows?.[0]?.start || 0 : 0;
  const paddingBottom =
    virtualRows.length > 0 ? totalSize - (virtualRows?.[virtualRows.length - 1]?.end || 0) : 0;
  if (loading) {
    return (
      <div style={{ height: 'calc(100vh - 330px)' }} className="loading">
        <Spin tip="Loading..." />
      </div>
    );
  }
  if (error) {
    return (
      <div className="warning-message-container">
        <Title level={5}>Something went wrong. Please try again later.</Title>
      </div>
    );
  }
  return (
    <div
      className="portfolio-performance-table"
      onScroll={(e) => fetchMoreOnBottomReached(e.target as HTMLDivElement)}
      ref={tableContainerRef}
    >
      <table>
        <thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => {
                return (
                  <th
                    key={header.id}
                    colSpan={header.colSpan}
                    style={{
                      height: '36px',
                      minWidth: header.getSize(),
                    }}
                    className={`${header.column.id === 'vendor_name' ? 'sticky-col2' : ''} ${
                      header.column.id === 'sf_grid_id' ? 'sticky-col1' : ''
                    }`}
                  >
                    {flexRender(header.column.columnDef.header, header.getContext())}
                  </th>
                );
              })}
            </tr>
          ))}
        </thead>
        <tbody>
          {paddingTop > 0 && (
            <tr>
              <td style={{ height: `${paddingTop}px` }} aria-label="Padding Top" />
            </tr>
          )}
          {virtualRows.map((virtualRow) => {
            const row = rows[virtualRow.index] as Row<PortfolioOverviewKPIs>;
            return (
              <tr
                style={{
                  height: `${virtualRow.size}px`,
                }}
                key={row?.id}
              >
                {row?.getVisibleCells().map((cell) => {
                  return (
                    <td
                      style={{
                        minWidth: cell.column.getSize(),
                      }}
                      className={`${cell.column.id === 'vendor_name' ? 'sticky-col2' : ''}${
                        cell.column.id === 'sf_grid_id' ? 'sticky-col1' : ''
                      }`}
                      key={cell.id}
                    >
                      {flexRender(cell.column.columnDef.cell, cell.getContext())}
                    </td>
                  );
                })}
              </tr>
            );
          })}

          {paddingBottom > 0 && (
            <tr>
              <td style={{ height: `${paddingBottom}px` }} aria-label="Padding Bottom" />
            </tr>
          )}
          <tr>
            {isFetchingNextPage ? (
              <td colSpan={100}>Loading...</td>
            ) : (
              <td colSpan={100}>
                Fetched {rows.length} of {totalDBRowCount} Rows.
              </td>
            )}
          </tr>
        </tbody>
      </table>
    </div>
  );
};
