import { useState } from 'react';
import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';

axios.defaults.baseURL = process.env.REACT_APP_VENDOR_DASHBOARD_SERVICE;

export const useAxios = (axiosParams: AxiosRequestConfig) => {
  const [response, setResponse] = useState<AxiosResponse>();
  const [error, setError] = useState<boolean | string>();
  const [loading, setLoading] = useState(false);

  const fetch = async (params: AxiosRequestConfig) => {
    try {
      setLoading(true);
      setError(false);
      const result = await axios.request(params);
      setResponse(result);
    } catch (err) {
      if (err instanceof AxiosError) {
        setError(err?.response?.data?.userMessage);
      } else {
        setError('Something went wrong. Please try again later.');
      }
    } finally {
      setLoading(false);
    }
  };

  const fetchData = () => {
    /* eslint-disable @typescript-eslint/no-floating-promises */
    fetch(axiosParams);
  };

  return { response, error, loading, fetchData };
};
