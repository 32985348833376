export const VendorDashboardLayoutTranslations = {
  title: {
    en: 'Vendor Dashboard',
    de: 'Titel',
  },
  VendorDashboardLayoutLastWeek: {
    en: 'Last 7 Days',
    de: 'Letzten 7 Tage',
  },
  VendorDashboardLayoutLastMonth: {
    en: 'Last 30 Days',
    de: 'Letzte 30 Tage',
  },
  VendorDashboardLayoutInThisMonth: {
    en: 'Select month',
    de: 'Wähle einen Monat',
  },
  VendorDashboardLayoutCustom: {
    en: 'Custom',
    de: 'Benutzerdefiniert',
  },
};
