import { Action, combineReducers, configureStore, ThunkAction } from '@reduxjs/toolkit';
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { authReducer } from '../services/auth/auth-slice';
import { i18nReducer } from '../services/i18n/i18nSlice';

const persistAuth = {
  key: 'auth',
  storage,
  blacklist: ['isInvalid', 'isLoaded'],
};

const reducers = combineReducers({
  auth: persistReducer(persistAuth, authReducer),
  i18n: i18nReducer,
});

export const store = configureStore({
  reducer: reducers,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

export const persistor = persistStore(store);

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
