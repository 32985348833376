export const dateRangeModalMoleculeTranslations = {
  dateRangeModalMoleculeTitle: {
    en: 'Select a Date Range',
    de: 'Wählen Sie einen Zeitraum aus',
  },
  dateRangeModalMoleculeFrom: {
    en: 'From:',
    de: 'Aus:',
  },
  dateRangeModalMoleculeTo: {
    en: 'To:',
    de: 'Zu:',
  },
};
