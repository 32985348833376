import React, { ReactElement, useState } from 'react';
import { CaretDownOutlined, CheckOutlined } from '@ant-design/icons';
import { Button, MenuProps } from 'antd';
import Dropdown from 'antd/es/dropdown';
import Spin from 'antd/es/spin';
import moment from 'moment';
import './metrics-tab-view-container.scss';
import { MomentDate } from '../../models/date.model';
import { useTranslations } from '../../services/i18n/useTranslations';
import { Filter, MenuItem, getItem, getMonthFilterOptions } from '../../shared/helper';
import { DateRangeModalMolecule } from '../date-range-modal-molecule/date-range-modal-molecule';

interface MetricsTabViewContainerProps {
  children: ReactElement;
  onRangeChange: (from: MomentDate, to: MomentDate) => void;
  onMonthChange: (month: MomentDate) => void;
  dailyKpisLoading: boolean;
  monthlyKpisLoading: boolean;
}
const EmptySpace = () => <>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</>;
const DATE_FORMAT = 'MMM DD, YYYY';
export const MetricsTabViewContainer = ({
  children,
  onRangeChange,
  dailyKpisLoading,
  monthlyKpisLoading,
  onMonthChange,
}: MetricsTabViewContainerProps) => {
  const { t } = useTranslations();
  const [showModal, setShowModal] = useState(false);
  const dateFilterOptions: {
    [key: string]: number;
  } = {
    [t.VendorDashboardLayoutLastWeek]: 7,
    [t.VendorDashboardLayoutLastMonth]: 30,
  };
  let to = moment().clone();
  let from = moment().subtract(dateFilterOptions[t.VendorDashboardLayoutLastWeek], 'd').clone();
  const [dropdownOption, setDropdownOption] = useState(t.VendorDashboardLayoutLastWeek);
  const [dropdownTitle, setDropdownTitle] = useState(
    `${moment(from)?.format(DATE_FORMAT)} - ${moment(to).format(DATE_FORMAT)}`,
  );

  const monthFilterOptions: Filter = getMonthFilterOptions();

  const handleModalOkAction = (fromDate: Date | undefined, toDate: Date | undefined): void => {
    if (fromDate && toDate) {
      setDropdownOption(t.VendorDashboardLayoutCustom);
      setDropdownTitle(
        `${moment(fromDate)?.format(DATE_FORMAT)} - ${moment(toDate).format(DATE_FORMAT)}`,
      );
      onRangeChange(moment(fromDate), moment(toDate));
    }
    setShowModal(false);
  };
  const handleMenuClick: MenuProps['onClick'] = (e) => {
    if (e.key === t.VendorDashboardLayoutCustom) {
      setShowModal(true);
      return;
    }
    if (e.key === t.VendorDashboardLayoutLastWeek || e.key === t.VendorDashboardLayoutLastMonth) {
      setDropdownOption(e.key);
      from = moment().clone().subtract(dateFilterOptions[e.key], 'days');
      to = moment().clone();
      setDropdownTitle(`${moment(from)?.format(DATE_FORMAT)} - ${moment(to).format(DATE_FORMAT)}`);
      onRangeChange(from, to);
    } else {
      setDropdownOption(e.key);
      setDropdownTitle(monthFilterOptions[e.key].label);
      onMonthChange(moment(monthFilterOptions[e.key].start));
    }
  };
  const items: MenuItem[] = [
    ...Object.keys(dateFilterOptions).map((key) =>
      getItem(key, key, key === dropdownOption ? <CheckOutlined /> : <EmptySpace />),
    ),
    getItem(
      t.VendorDashboardLayoutInThisMonth,
      t.VendorDashboardLayoutInThisMonth,
      <EmptySpace />,
      [
        ...Object.keys(monthFilterOptions).map((key) =>
          getItem(key, key, key === dropdownOption ? <CheckOutlined /> : <EmptySpace />),
        ),
      ],
      'light',
    ),
    getItem(
      t.VendorDashboardLayoutCustom,
      t.VendorDashboardLayoutCustom,
      t.VendorDashboardLayoutCustom === dropdownOption ? <CheckOutlined /> : <EmptySpace />,
    ),
  ];
  if (dailyKpisLoading || monthlyKpisLoading) {
    return (
      <div className="loading">
        <Spin tip="Loading..." />
      </div>
    );
  }

  return (
    <div className="vendor-dashboard-layout">
      <div className="vendor-dashboard-layout-title">
        <div style={{ paddingBottom: '10px', fontSize: '1.125rem' }}>{t.title}</div>
        <Dropdown
          trigger={['click']}
          disabled={dailyKpisLoading}
          menu={{ items, onClick: handleMenuClick }}
        >
          <Button>
            {dropdownTitle} <CaretDownOutlined />
          </Button>
        </Dropdown>
      </div>
      <div className="vendor-dashboard-layout-container">{children}</div>

      <DateRangeModalMolecule
        format="MMM DD, YYYY"
        onOk={handleModalOkAction}
        showModal={showModal}
        closeModal={() => setShowModal(false)}
      />
    </div>
  );
};
