import React, { useEffect, useState } from 'react';
import Title from 'antd/es/typography/Title';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import './vendor-dashboard-page.scss';
import { MetricsSectionViewContainer } from '../../components/metrics-section-view-container/metrics-section-view-container';
import { MetricsTabViewContainer } from '../../components/metrics-tab-view-container/metrics-tab-view-container';
import { SectionView } from '../../components/section-view/section-view';
import { TabView } from '../../components/tab-view/tab-view';
import { MomentDate } from '../../models/date.model';
import { getIsInvalid, getToken, setSessionToken } from '../../services/auth/auth-slice';
import { Filter, getMonthFilterOptions } from '../../shared/helper';
import { useAppDispatch, useAppSelector } from '../../shared/hooks';
import { ActionData, useSalesforceTrigger } from '../../shared/useAnalytics';
import { useAxios } from '../../shared/useAxios';

const DATE_FORMAT = 'YYYY-MM-DD';

export const VendorDashboardPage = () => {
  const monthFilterOptions: Filter = getMonthFilterOptions();

  const [monthFilter, setMonthFilter] = useState<moment.Moment>(moment().subtract(1, 'months'));
  const vdToken = useSelector(getToken);
  const isInvalidSession = useAppSelector(getIsInvalid);
  const [searchParams] = useSearchParams();
  const accId: string = searchParams.get('id') || '';
  const sfToken: string = searchParams.get('sessionId') || '';
  const view: string = searchParams.get('view') || 'tab';
  const dispatch = useAppDispatch();
  const [showMonthlyKpis, setshowMonthlyKpis] = React.useState<boolean>(false);
  const { triggerHover, triggerClick } = useSalesforceTrigger('VendorDashboard');
  const [dateRange, setDateRange] = useState<{
    to: string;
    from: string;
  }>({
    to:
      view === 'tab'
        ? moment().format(DATE_FORMAT)
        : monthFilterOptions[Object.keys(monthFilterOptions)[0]].end.format(DATE_FORMAT),
    from:
      view === 'tab'
        ? moment().subtract(7, 'days').format(DATE_FORMAT)
        : monthFilterOptions[Object.keys(monthFilterOptions)[0]].start.format(DATE_FORMAT),
  });
  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    dispatch(setSessionToken(sfToken));
  }, []);
  const {
    response: dailyKpis,
    loading: dailyKpisLoading,
    error: dailyKpisError,
    fetchData: fetchDailyKpis,
  } = useAxios({
    method: 'get',
    url: `/vendor-dashboard-service/kpis?from=${dateRange.from}&to=${dateRange.to}&sf_grid_id=${accId}`,
    headers: {
      'x-vendorDashboard-token': vdToken,
    },
  });
  const {
    response: monthlyKpis,
    loading: monthlyKpisLoading,
    error: monthlyKpisError,
    fetchData: fetchMonthlyKpis,
  } = useAxios({
    method: 'get',
    url: `/vendor-dashboard-service/ncr-metrics?month=${monthFilter.month()}&year=${monthFilter.year()}&sf_grid_id=${accId}`,
    headers: {
      'x-vendorDashboard-token': vdToken,
    },
  });
  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    if (!isInvalidSession) {
      fetchDailyKpis();
    }
  }, [dateRange, vdToken]);
  useEffect(() => {
    if (!isInvalidSession) {
      fetchMonthlyKpis();
    }
  }, [monthFilter, vdToken]);
  const onRangeChange = (from: MomentDate, to: MomentDate) => {
    //  Custom events for Vendor Adoption metrics AMI-575
    const dateRangeSelection = {
      to: to?.format(DATE_FORMAT) || '',
      from: from?.format(DATE_FORMAT) || '',
    };
    const actionData: ActionData = {
      duration: {
        value: to?.diff(from, 'days').toString() || '',
        unit: 'days',
      },
      actionName: 'filter',
    };
    triggerClick(actionData);
    setDateRange(dateRangeSelection);
    setshowMonthlyKpis(false);
  };
  const onMonthChange = (from: MomentDate) => {
    const actionData: ActionData = {
      duration: {
        value: from?.toString() || '',
        unit: 'Month',
      },
      actionName: 'filter',
    };
    triggerClick(actionData);
    const dateRangeSelection = {
      to: from?.endOf('month').format(DATE_FORMAT) || '',
      from: from?.date(1).format(DATE_FORMAT) || '',
    };
    setMonthFilter(from || moment().subtract(1, 'months'));
    setDateRange(dateRangeSelection);
    setshowMonthlyKpis(true);
  };
  if (isInvalidSession) {
    return (
      <div className="warning-message-container">
        <Title level={5}>Invalid token</Title>
      </div>
    );
  }

  if (accId === '') {
    return (
      <div className="warning-message-container">
        <Title level={5}>Account ID is not provided.</Title>
      </div>
    );
  }
  return (
    <div
      onMouseEnter={(e) => {
        triggerHover(e.type);
      }}
      onMouseLeave={(e) => {
        triggerHover(e.type);
      }}
      className="vendor-dashboard-organism"
    >
      {view === 'tab' ? (
        <MetricsTabViewContainer
          dailyKpisLoading={dailyKpisLoading}
          monthlyKpisLoading={monthlyKpisLoading}
          onRangeChange={onRangeChange}
          onMonthChange={onMonthChange}
        >
          <TabView
            showMonthlyKpis={showMonthlyKpis}
            dailyData={dailyKpis?.data?.data}
            monthlyData={monthlyKpis?.data?.data}
            dailyDataError={dailyKpisError}
            monthlyDataError={monthlyKpisError}
          />
        </MetricsTabViewContainer>
      ) : (
        <MetricsSectionViewContainer
          dailyKpisLoading={dailyKpisLoading}
          monthlyKpisLoading={monthlyKpisLoading}
          onMonthChange={onMonthChange}
        >
          <SectionView
            dailyData={dailyKpis?.data?.data}
            monthlyData={monthlyKpis?.data?.data}
            dailyDataError={dailyKpisError}
            monthlyDataError={monthlyKpisError}
          />
        </MetricsSectionViewContainer>
      )}
    </div>
  );
};
