import React from 'react';
import { Space, Tabs, TabsProps, Typography } from 'antd';
import { DataGridData } from '../../models/kpi.model';
import { KpiCard } from '../kpi-card/kpi-card';
import { columns } from './card-def';

interface TabViewProps {
  showMonthlyKpis: boolean;
  dailyData: DataGridData;
  monthlyData: DataGridData;
  dailyDataError: string | boolean | undefined;
  monthlyDataError: string | boolean | undefined;
}
const { Text } = Typography;

export const TabView = ({
  showMonthlyKpis,
  dailyData,
  monthlyData,
  dailyDataError,
  monthlyDataError,
}: TabViewProps) => {
  const items: TabsProps['items'] = [
    {
      key: 'Revenue',
      label: `Revenue`,
      children: (
        <>
          {dailyDataError || !dailyData?.kpis?.length ? (
            <Space direction="vertical" align="center">
              <Text>
                {dailyDataError ||
                  'No data is available for that period of time. Please choose other dates.'}
              </Text>
              <img alt="" src="/illustration.png" />
            </Space>
          ) : (
            <Space align="start" size={[8, 8]} wrap>
              {columns.revenue.map((kpidefinition) => {
                const kpi = dailyData?.kpis?.find((obj) => obj.name === kpidefinition.id);
                return (
                  kpi && (
                    <KpiCard
                      prevInterval={dailyData.prevInterval}
                      kpi={kpi}
                      key={`kpi-key-${kpi.name}`}
                    />
                  )
                );
              })}
            </Space>
          )}
        </>
      ),
    },
    {
      key: 'Commercial',
      label: `Commercial`,
      children: (
        <>
          {!showMonthlyKpis || monthlyDataError || !monthlyData?.kpis?.length ? (
            <Space direction="vertical" align="center">
              {!showMonthlyKpis ? (
                <Text>
                  Commercial KPIs can only be filtered by month, please change the filter.
                </Text>
              ) : (
                <Text>
                  {monthlyDataError ||
                    'No data is available for that period of time. Please choose other dates.'}
                </Text>
              )}
              <img alt="" src="/illustration.png" />
            </Space>
          ) : (
            <Space align="start" size={[8, 8]} wrap>
              {columns.commercial.map((kpidefinition) => {
                const kpi = monthlyData?.kpis?.find((obj) => obj.name === kpidefinition.id);
                return (
                  kpi && (
                    <KpiCard
                      kpi={kpi}
                      key={`kpi-key-${kpi.name}`}
                      prevInterval={monthlyData.prevInterval}
                      subKpis={kpidefinition?.children?.map((def) =>
                        monthlyData?.kpis?.find((subKpi) => subKpi.name === def.id),
                      )}
                    />
                  )
                );
              })}
            </Space>
          )}
        </>
      ),
    },
    {
      key: 'Operations',
      label: `Operations`,
      children: (
        <>
          {dailyDataError || !dailyData?.kpis?.length ? (
            <Space direction="vertical" align="center">
              <Text>
                {dailyDataError ||
                  'No data is available for that period of time. Please choose other dates.'}
              </Text>
              <img alt="" src="/illustration.png" />
            </Space>
          ) : (
            <Space align="start" size={[8, 8]} wrap>
              {columns.operations.map((kpidefinition) => {
                const kpi = dailyData?.kpis?.find((obj) => obj.name === kpidefinition.id);
                return (
                  kpi && (
                    <KpiCard
                      prevInterval={dailyData.prevInterval}
                      kpi={kpi}
                      key={`kpi-key-${kpi.name}`}
                    />
                  )
                );
              })}
            </Space>
          )}
        </>
      ),
    },
  ];

  return <Tabs defaultActiveKey="1" items={items} />;
};
