import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import axios from 'axios';
import { CredentialsResponse } from '../../models/credentials.model';
import { REACT_APP_VENDOR_DASHBOARD_SERVICE } from '../../models/env.model';
import type { AppThunk, RootState } from '../../shared/store';

export interface SessionState {
  token: string;
  isInvalid: boolean;
  isLoaded: boolean;
}

const initialState: SessionState = {
  token: '',
  isInvalid: true,
  isLoaded: false,
};

export const authSlice = createSlice({
  name: 'session',
  initialState,
  reducers: {
    setToken: (state, action: PayloadAction<string>) => {
      state.token = action.payload;
      state.isInvalid = false;
    },
    invalidToken: (state) => {
      state.token = '';
      state.isInvalid = true;
    },
    setLoaded: (state) => {
      state.isLoaded = true;
    },
  },
});

// actions
export const { setToken, invalidToken, setLoaded } = authSlice.actions;

export const getToken = (state: RootState) => state.auth.token;
export const getIsInvalid = (state: RootState) => state.auth.isInvalid;
export const getIsLoaded = (state: RootState) => state.auth.isLoaded;

// thunk
export const setSessionToken =
  (token: string): AppThunk =>
  async (dispatch) => {
    try {
      const response: CredentialsResponse = await axios.get(
        `${REACT_APP_VENDOR_DASHBOARD_SERVICE}/vendor-dashboard-service/login`,
        {
          headers: {
            'x-salesforce-token': token,
          },
        },
      );
      const { credential } = response.data;
      dispatch(setToken(credential));
    } catch (err) {
      dispatch(invalidToken());
    } finally {
      dispatch(setLoaded());
    }
  };

export const authReducer = authSlice.reducer;
