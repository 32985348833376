import React, { useState } from 'react';
import { CloseOutlined } from '@ant-design/icons';
import { Row, Col, Typography, DatePicker, Modal } from 'antd';
import dayjs from 'dayjs';
import { useTranslations } from '../../services/i18n/useTranslations';
import './date-range-modal-molecule.scss';

const { Paragraph } = Typography;

interface DateRangeModalMoleculeProps {
  format: string;
  showModal: boolean;
  closeModal: () => void;
  onOk: (fromDate: Date, toDate: Date) => void;
}

export const DateRangeModalMolecule = ({
  format,
  showModal,
  closeModal,
  onOk,
}: DateRangeModalMoleculeProps) => {
  const { t } = useTranslations();
  const today = new Date();
  today.setDate(today.getDate() + 1);
  const [fromDate, setFromDate] = useState<Date>();
  const [toDate, setToDate] = useState<Date>();
  const disabledDateFirstPicker = (current: Date) => {
    if (!current) {
      return true;
    }
    return current > today;
  };

  const handleModalOnOk = () => {
    onOk(fromDate!, toDate!);
  };

  return (
    <Modal
      title={t.dateRangeModalMoleculeTitle}
      centered
      transitionName=""
      open={showModal}
      okButtonProps={{ disabled: !(fromDate && toDate) }}
      okText="Apply"
      onOk={() => handleModalOnOk()}
      onCancel={() => closeModal()}
      className="date-range-modal-molecule"
      closeIcon={<CloseOutlined style={{ fontSize: '14px' }} />}
    >
      <Row align="middle">
        <Col flex="1">
          <Paragraph>{t.dateRangeModalMoleculeFrom}</Paragraph>
          <DatePicker
            placeholder="Select Date"
            format={format}
            disabledDate={(date) => disabledDateFirstPicker(date?.toDate())}
            /* eslint-disable @typescript-eslint/no-non-null-asserted-optional-chain */
            onChange={(date) => setFromDate(date?.toDate()!)}
            value={fromDate && dayjs(fromDate)}
          />
        </Col>
        <Col flex="1">
          <Paragraph>{t.dateRangeModalMoleculeTo}</Paragraph>
          <DatePicker
            placeholder="Select Date"
            format={format}
            disabledDate={(date) => disabledDateFirstPicker(date?.toDate())}
            /* eslint-disable @typescript-eslint/no-non-null-asserted-optional-chain */
            onChange={(date) => setToDate(date?.toDate()!)}
            value={toDate && dayjs(toDate)}
          />
        </Col>
      </Row>
    </Modal>
  );
};
